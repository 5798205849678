import React, { Fragment } from 'react';
import './HomeGames.css'
import data from '../../JSON/gameData.json'
import GameCard from '../GameCard/GameCard';
import { FaArrowCircleUp } from "react-icons/fa";
import { scrollToTop } from '../../Custom/custom';
import AdComponent from '../AdComponent/AdComponent';

function HomeGames() {


    return (
        <>
            <div className='scrollTop cursor-pointer' onClick={() => scrollToTop()}><FaArrowCircleUp size={30} color='#f67905' /></div>
            <div className='bgContent p-3'>
                <div className='my-container'>
                    {
                        data.map((element, index) => {
                            // Check if it's the start of a new group
                            if (index % 18 === 0) {
                                return (
                                    <div className='row m-0 g-1' key={`group-${index}`}>
                                        {data.slice(index, index + 18).map((item, subIndex) => (
                                            <div className='col-6 col-sm-4 col-lg-2 p-2' key={subIndex}>
                                                <GameCard data={item} />
                                            </div>
                                        ))}
                                    </div>
                                );
                            }
                            return null; // Don't render anything for indices that don't start a group
                        })
                    }

                    {/* <div className='row m-0 g-1'>
                        {
                            data.map((element, index) => {
                                return <Fragment key={index}>
                            {(index == 18) &&
                                <div className='col-12'>
                                    <AdComponent
                                        client="ca-pub-2248973218739803"
                                        slot="6874956263"
                                        format="auto"
                                        style={{ display: 'block' }}
                                        />
                                </div>
                            }
                            <div key={index} className='col-6 col-sm-4 col-lg-3 col-xl-2 p-2'>
                                    <GameCard data={element} />
                                </div>
                        </Fragment>
                                  
                            })
                        }
                    </div> */}
                    {/* {<AdComponent
                        client="ca-pub-2248973218739803"
                        slot="1462203285"
                        format="autorelaxed"
                        style={{ display: 'block' }}
                    />} */}
                    <section className='p-3 rounded' style={{ backgroundColor: '#353535', color: 'rgba(255, 255, 255, 0.623)' }}>
                        <div className='h6'>Welcome to PlayPokiGames: Your Ultimate Free Gaming Portal!</div>

                        <p>Dive into the world of endless fun and excitement with PlayPokiGames, your one-stop destination for free online games. Whether you're a casual gamer looking to pass the time or a hardcore enthusiast seeking your next challenge, we've got something for everyone!</p>

                        <div className='h4'>Explore Our Game Categories:</div>
                        <ul>
                            <li><b className='fw-bold'>Action</b>: Test your reflexes and strategy with adrenaline-pumping action games.</li>
                            <li><b className='fw-bold'>Adventure</b>: Embark on epic journeys and unravel exciting storylines.</li>
                            <li><b className='fw-bold'>Puzzle</b>: Challenge your mind with brain-teasing puzzles and logic games.</li>
                            <li><b className='fw-bold'>Sports</b>: Get into the game with our collection of sports simulations.</li>
                            <li><b className='fw-bold'>Arcade</b>: Relive the classics or discover new arcade hits.</li>
                            <li><b className='fw-bold'>Multiplayer</b>: Connect with friends or players around the world for competitive fun.
                            </li>
                        </ul>
                        <div className='h4'>Why Choose PlayPokiGames?</div>
                        <ul>
                            <li><b className='fw-bold'>
                                Free to Play</b>: Enjoy thousands of games without any cost.</li>
                            <li><b className='fw-bold'>No Downloads Required</b>: Play instantly from your browser.</li>
                            <li><b className='fw-bold'>Regular Updates</b>: New games added every week to keep the fun going.</li>
                            <li><b className='fw-bold'>User-Friendly Interface</b>: Easy navigation to find and play your favorite games.</li>
                            <li><b className='fw-bold'>Community Features</b>: Join our community to share tips, reviews, and high scores.</li>
                        </ul>
                        <div className='h4'>Get Started:</div>
                        <ul>
                            <li><b className='fw-bold'>Browse</b>: Explore our vast collection of games.</li>
                            <li><b className='fw-bold'>Play</b>: Click on a game to start playing immediately.</li>
                            <li><b className='fw-bold'>Enjoy</b>: Have fun and don't forget to bookmark PlayPokiGames for easy access.</li>

                        </ul>
                        <p> Join millions of players worldwide and start your gaming adventure with PlayPokiGames today! </p>
                    </section>
                </div>
            </div>
        </>
    )
}

export default HomeGames