import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import data from "../../JSON/gameData.json";
import { Modal, Spinner } from 'react-bootstrap';
import AdComponent from '../AdComponent/AdComponent';

function GameIframe(props) {
  let [timer, setTimer] = useState(5);
  const { gameId } = useParams();
  const [loader, setLoader] = useState(true)
  const [game, setgame] = useState({})

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  useEffect(() => {
    if (gameId) {
      let loadGame = data.find(x => x.shortUrl == gameId)
      if (loadGame) {
        setgame({ ...loadGame })
        window.gtag("event", "game", {
          event_category: "game",
          event_label: loadGame?.title
        });
      }
    }
  }, [gameId])

  useEffect(() => {
    // setShow(true);
    setTimeout(() => {
      setLoader(false)
    }, 2000);
  }, [])

  useEffect(() => {
    var timerID = setInterval(() => {
      if (timer > 0) {
        timer -= 1;
        setTimer(timer);
      }else{
        clearInterval(timerID)
      }
    }, 1000);

    return () => clearInterval(timerID)
  },[]);

  return (
    <>
      {
        loader && <div className='loader'>
          <Spinner animation="border" variant='light' role="status"></Spinner>
        </div>
      }
      <div className="bgContent-iframe p-0">
        <div className="iframe-container">
          {
            game.id && <iframe src={game.gameUrl}></iframe>
          }

        </div>
      </div>
      {/* <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header >
          <Modal.Title id="contained-modal-title-center">ADVERTISEMENT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {<AdComponent
            client="ca-pub-2248973218739803"
            slot="6874956263"
            format="auto"
            style={{ display: 'block' }}
          />}
        </Modal.Body>
        {
          timer > 0 && <div style={{ background: 'transparent', position: 'relative', top: '60px', color: '#fff', textAlign: 'center', fontSize: 'larger' }}>
            Continue in {timer > 0 ? timer : 0}s
          </div>
        }
        {
          timer <= 0 && <div style={{ background: 'transparent', position: 'relative', top: '60px', color: '#fff', textAlign: 'center', fontSize: 'larger' }}>
            <button className="adbutton mt-3" onClick={handleClose}>
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" class="mt-m-1 text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z">
                </path>
              </svg>
              <b class="text-[#ffff] text-xl">  Continue</b></button>
          </div>
        }
      </Modal> */}
    </>
  )
}

export default GameIframe